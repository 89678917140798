/*global confirm, navigator */
import Logger from "js-logger";
import ko from "knockout";
import objects from "meta-client/modules/objects";
import metaModel from "meta-core/modules/model";
import metaProtocol from "meta-core/modules/protocol";
import model from "portal-core/modules/model";
import protocol from "portal-core/modules/protocol";
import coreViewmodel from "portal-core/modules/viewmodel";
import _ from "underscore";
import ext from "util-web/modules/ext";
import i18next from "util-web/modules/i18next";
import nav from "util-web/modules/nav";
import ui from "util-web/modules/ui";
import template from "./buchhaltung.html";

const LOG = Logger.get("portal-web/component/buchhaltung");
const PORTAL_PLUS_PRICE = 95;

export default Object.freeze({
    template,
    viewModel: {
        createViewModel: function ({viewmodel}) {
            const vm = {
                adminLoadOwnGroups: () => vm.viewmodel.adminLoadOwnGroups(true),
                ansprechpartnerTitle: i18next.pureComputedT("ansprechpartner"),
                belegeTitle: i18next.pureComputedT("buchhaltung_beleg"),
                delayedEigbs: ko.observableArray(),
                deleteZipTitle: i18next.pureComputedT("buchhaltung_zip_delete"),
                dokumenteTitle: i18next.pureComputedT("buchhaltung_dokument"),
                downloadZipTitle: i18next.pureComputedT("buchhaltung_zip_download"),
                eigBuchhaltungType: model.eigBuchhaltungType,
                focusPersFilter() {
                    vm.hasPersFilterFocus(true);
                    return true;
                },
                hasNoEigbMatches: ko.observable(true),
                hasPersFilterFocus: ko.observable(false),
                hasSearchQueryFocus: ko.observable(false),
                i18next,
                isSearchQueryValid: ko.pureComputed(() => _.isEmpty(vm.searchQuery()) === false),
                newDataTitle: i18next.pureComputedT("buchhaltung_new_data"),
                nonAdminTitle: i18next.pureComputedT("buchhaltung_non_admin"),
                portalPlusState: coreViewmodel.eigBuchhaltungVm.ppState,
                price: PORTAL_PLUS_PRICE,
                resetSearchQuery: () => vm.searchQuery(undefined),
                searchQuery: ko.observable(),
                shareZipTitle: i18next.pureComputedT("buchhaltung_zip_share"),
                viewmodel,
                zipState: model.zipGeschaeftsjahr.state
            };

            vm.reset = function () {
                vm.activeBuchhaltung(undefined);
                vm.hasPersFilterFocus(false);
                vm.resetSearchQuery();
            };
            viewmodel.onEigbLoad(vm.reset);

            vm.buchhaltungen = ko.pureComputed(function () {
                const query = vm.searchQuery();
                const hasQuery = _.isEmpty(query) === false;
                const stringQuery = (
                    hasQuery
                        ? query.toLowerCase()
                        : ""
                );
                const selectedPersIds = vm.viewmodel.filterPersSelectedIds();
                const matchingEigbs = vm.viewmodel.eigBuchhaltungen().filter(function (eigb) {
                    const hasQueryMatch = hasQuery === false || 0 <= eigb.bez.toLowerCase().indexOf(stringQuery) || 0 < eigb.buchhaltungen.filter((liegb) => 0 <= liegb.bez.toLowerCase().indexOf(stringQuery)).length || 0 <= eigb.ref.indexOf(stringQuery) || 0 < eigb.buchhaltungen.filter((liegb) => 0 <= liegb.ref.indexOf(stringQuery)).length;
                    const hasPersMatch = selectedPersIds.length <= 0 || 0 < _.intersection(eigb.personen.map((pers) => pers.persId), selectedPersIds).length;
                    return hasQueryMatch && hasPersMatch;
                });
                vm.hasNoEigbMatches((hasQuery || 0 < selectedPersIds.length) && matchingEigbs.length <= 0);
                if (hasQuery || 0 < selectedPersIds.length) {
                    return matchingEigbs;
                }
                return vm.viewmodel.eigBuchhaltungen();
            });

            vm.buchhaltungenWithNewData = ko.pureComputed(() => vm.buchhaltungen().filter((eigb) => vm.viewmodel.hasNewData(eigb)));
            vm.buchhaltungenWithoutNewData = ko.pureComputed(() => vm.buchhaltungen().filter((eigb) => vm.viewmodel.hasNewData(eigb) === false));

            vm.activeBuchhaltung = ko.observable();
            vm.activeBuchhaltungGeschaeftsjahre = ko.pureComputed(function () {
                const eigb = vm.activeBuchhaltung();
                if (eigb) {
                    return viewmodel.getAccessibleGeschaeftsjahre(eigb);
                }
                return [];
            });
            vm.activeBuchhaltungPersonen = ko.pureComputed(function () {
                const eigb = vm.activeBuchhaltung();
                if (eigb) {
                    const userPersIds = vm.viewmodel.userPersonen().map((pers) => pers.persId);
                    return eigb.personen.filter((pers) => userPersIds.includes(pers.persId));
                }
                return [];
            });
            vm.activeBuchhaltungPortalPlusAbos = ko.pureComputed(function () {
                const eigb = vm.activeBuchhaltung();
                if (eigb) {
                    return vm.viewmodel.ppAbos.objects().filter(function (ppAbo) {
                        return ppAbo.eigbId() === eigb.eigbId;
                    }).sort(ext.simpleSort.bind({
                        property: "beginDate",
                        reverse: true
                    }));
                }
                return [];
            });

            vm.activateBuchhaltung = function (eigb) {
                vm.activeBuchhaltung(eigb);
                return vm.loadZips().then(function () {
                    ko.tasks.schedule(function () {
                        const firstGeschaeftsjahrAbgeschlossen = vm.activeBuchhaltungGeschaeftsjahre().find((geschaeftsjahr) => geschaeftsjahr.abgeschlossen)?.geschaeftsjahr;
                        if (firstGeschaeftsjahrAbgeschlossen) {
                            vm.zipGeschaeftsjahr(firstGeschaeftsjahrAbgeschlossen);
                        }
                    });
                });
            };

            vm.navigateToBuchhaltung = (eigb) => vm.viewmodel.navigateToBuchhaltung(eigb.eigbId);

            vm.selectEigb = function (eigb) {
                vm.viewmodel.resetEigbFilter();
                vm.viewmodel.resetLiegbFilter();
                eigb.selected(true);
            };

            vm.showBelegeEigb = function (eigb) {
                vm.selectEigb(eigb);
                vm.viewmodel.navigateToBeleg({state: {search: true}});
            };

            vm.showDokumenteEigb = function (eigb) {
                vm.selectEigb(eigb);
                vm.viewmodel.navigateToDokument({search: true});
            };

            vm.showFreigabeEigb = function (eigb) {
                vm.selectEigb(eigb);
                vm.viewmodel.navigateToFreigabe({search: true});
            };

            vm.selectLiegb = function (liegb) {
                vm.viewmodel.resetEigbFilter();
                vm.viewmodel.resetLiegbFilter();
                vm.viewmodel.liegBuchhaltungenById().get(liegb.liegbId)?.selected(true);
            };

            vm.showBelegeLiegb = function (liegb) {
                vm.selectLiegb(liegb);
                vm.viewmodel.navigateToBeleg({state: {search: true}});
            };

            vm.showDokumenteLiegb = function (liegb) {
                vm.selectLiegb(liegb);
                vm.viewmodel.navigateToDokument({search: true});
            };

            vm.activateAnsprechpartnerEigb = function (eigb) {
                const ansprechpartner = vm.viewmodel.resolveAnsprechpartner({eigbId: eigb.eigbId});
                if (ansprechpartner) {
                    vm.viewmodel.activeAnsprechpartner(ansprechpartner);
                }
            };
            vm.activateAnsprechpartnerLiegb = function (liegb) {
                const ansprechpartner = vm.viewmodel.resolveAnsprechpartner({liegbId: liegb.liegbId});
                if (ansprechpartner) {
                    vm.viewmodel.activeAnsprechpartner(ansprechpartner);
                }
            };

            vm.zips = objects({
                loadById: function (metaId) {
                    return objects.loadByMetaId(vm.viewmodel.client, metaId).then(function (searchReply) {
                        const meta = _.first(searchReply.metas);
                        if (meta) {
                            const eigbId = _.get(meta, ["value", "eigbId"], 0);
                            if (eigbId === vm.activeBuchhaltung().eigbId) {
                                return searchReply;
                            }
                        }
                    });
                },
                toVm: (meta) => coreViewmodel.toZipGeschaeftsjahrVm(meta, vm.viewmodel.resolvePerson),
                typeId: model.ZIPGESCHAEFTSJAHR_TYPE_ID,
                unshift: true
            });
            vm.zipsSub = vm.viewmodel.client.registerOnEvent(vm.zips);

            vm.isLoadingZips = ko.observable(false);

            vm.loadZips = function () {
                vm.isLoadingZips(true);
                vm.zips.clear();
                const eigb = vm.activeBuchhaltung();
                const eigbId = eigb.eigbId;
                return vm.zips.processSearch(
                    vm.viewmodel.client.search(metaProtocol.searchRequest({
                        cursor: vm.zips.searchCursor(),
                        limit: 0,
                        query: vm.zips.newSearchQuery([`eigbId:${eigbId}`]),
                        sort: metaProtocol.searchRequestSort({field: "timestamp", reverse: true})
                    }))
                ).then(function () {
                    const belegTimestamp = eigb.belegTimestamp;
                    const dokumentTimestamp = eigb.dokumentTimestamp;
                    vm.zips.objects().forEach(function (zipVm) {
                        zipVm.isOutdated((belegTimestamp && zipVm.timestamp() < belegTimestamp) || (dokumentTimestamp && zipVm.timestamp() < dokumentTimestamp));
                    });
                    vm.viewmodel.errorReply(undefined);
                }, function (exc) {
                    vm.viewmodel.errorReply(JSON.stringify(exc, undefined, 4));
                    LOG.warn("loadZips failed", exc);
                }).then(() => vm.isLoadingZips(false));
            };

            vm.zipGeschaeftsjahr = ko.observable();
            vm.isZipGeschaeftsjahrValid = ko.pureComputed(() => _.isFinite(vm.zipGeschaeftsjahr()));
            vm.zipPerson = ko.observable();
            vm.isZipPersonValid = ko.pureComputed(() => _.isObject(vm.zipPerson()));
            vm.zipPersonOptionsCaption = ko.pureComputed(function () {
                i18next.currentLocale();
                return (
                    1 < vm.activeBuchhaltungPersonen().length
                        ? i18next.t("buchhaltung_download_person")
                        : undefined
                );
            });
            vm.hasPendingZipGeschaefsjahr = ko.observable(false);

            vm.executeZipGeschaeftsjahr = function (form) {
                if (ui.validateForm(form) === false) {
                    return;
                }
                const eigbId = vm.activeBuchhaltung().eigbId;
                return vm.viewmodel.client.execute(metaProtocol.executeRequest({
                    actionId: model.ZIP_GESCHAEFTSJAHR_ACTION_ID,
                    param: model.zipGeschaeftsjahrActionParam({
                        eigbId,
                        persId: vm.zipPerson().persId,
                        year: vm.zipGeschaeftsjahr()
                    })
                })).then(function () {
                    vm.viewmodel.errorReply(undefined);
                    vm.hasPendingZipGeschaefsjahr(false);
                    ui.resetForm(form);
                }, function (exc) {
                    const errorCode = _.get(exc, "code", 0);
                    if (protocol.ZIP_GESCHAEFTSJAHR_PENDING_ERROR === errorCode) {
                        LOG.info(`executeZipGeschaeftsjahr ZIP_GESCHAEFTSJAHR_PENDING_ERROR`);
                        vm.hasPendingZipGeschaefsjahr(true);
                    } else {
                        vm.viewmodel.errorReply(JSON.stringify(exc, undefined, 4));
                        LOG.warn("executeZipGeschaeftsjahr failed", exc);
                    }
                });
            };

            vm.activeShareZip = ko.observable();
            vm.activateShareZip = (zip) => vm.activeShareZip(zip);
            vm.copyActiveShareZipUrl = () => navigator.clipboard.writeText(vm.activeShareZip().url());

            vm.deleteZip = function (zip) {
                if (model.zipGeschaeftsjahr.state.EMPTY !== zip.state() && confirm(i18next.t("buchhaltung_zip_delete_confirmation")) === false) {
                    return;
                }
                const id = zip.id;
                LOG.debug(`deleteZip, id=${id}`);
                return vm.viewmodel.client.write(metaProtocol.writeRequest({
                    action: metaProtocol.WRITE_ACTION.DELETE,
                    channel: protocol.ZIP_CHANNEL,
                    meta: metaModel.metaObject({
                        id,
                        typeId: model.ZIPGESCHAEFTSJAHR_TYPE_ID
                    })
                })).then(function () {
                    vm.viewmodel.errorReply(undefined);
                }, function (exc) {
                    vm.viewmodel.errorReply(JSON.stringify(exc, undefined, 4));
                    LOG.warn("deleteZip failed", exc);
                });
            };

            vm.isCancelPortalPlusActive = ko.observable(false);
            vm.activateCancelPortalPlus = () => vm.isCancelPortalPlusActive(true);
            vm.isPortalPlusProcessing = ko.observable(false);

            vm.buchhaltungenWithPortalPlusEnabled = ko.pureComputed(function () {
                const activeEigbId = vm.activeBuchhaltung()?.eigbId ?? 0;
                return vm.viewmodel.eigBuchhaltungen().filter(
                    (eigb) => eigb.isAdmin() && eigb.ppOverride === false && vm.portalPlusState.ENABLED === eigb.ppState()
                ).map(function (eigb) {
                    return {
                        bez: eigb.bez,
                        eigbId: eigb.eigbId,
                        selected: ko.observable(activeEigbId === eigb.eigbId)
                    };
                });
            });

            vm.selectedEnabledEigbIds = ko.pureComputed(function () {
                return vm.buchhaltungenWithPortalPlusEnabled().filter((eigb) => eigb.selected()).map((eigb) => eigb.eigbId);
            });

            vm.executeCancelPortalPlus = function (form) {
                if (ui.validateForm(form) === false) {
                    return;
                }
                vm.isPortalPlusProcessing(true);
                return vm.viewmodel.client.execute(metaProtocol.executeRequest({
                    actionId: model.PORTALPLUS_CANCEL_ACTION_ID,
                    param: model.portalPlusCancelActionParam({
                        eigbIds: vm.selectedEnabledEigbIds()
                    })
                })).then(function () {
                    vm.viewmodel.errorReply(undefined);
                    ui.resetForm(form);
                    vm.isCancelPortalPlusActive(false);
                }, function (exc) {
                    vm.viewmodel.errorReply(JSON.stringify(exc, undefined, 4));
                    LOG.warn("executeCancelPortalPlus failed", exc);
                }).then(function () {
                    vm.isPortalPlusProcessing(false);
                });
            };

            vm.canEnablePortalPlus = function (eigb) {
                return (
                    eigb.isAdmin()
                    && eigb.isGueltig
                    && eigb.ppOverride === false
                    && vm.portalPlusState.DISABLED === eigb.ppState()
                );
            };

            vm.isEnablePortalPlusActive = ko.observable(false);
            vm.activateEnablePortalPlus = () => vm.isEnablePortalPlusActive(true);

            vm.buchhaltungenWithPortalPlusDisabled = ko.pureComputed(function () {
                const activeEigbId = vm.activeBuchhaltung()?.eigbId ?? 0;
                return vm.viewmodel.eigBuchhaltungen().filter(
                    (eigb) => eigb.isAdmin() && eigb.ppOverride === false && vm.portalPlusState.DISABLED === eigb.ppState()
                ).map(function (eigb) {
                    const noOfLiegb = eigb.buchhaltungen.filter((liegb) => liegb.isGueltig).length;
                    return {
                        bez: eigb.bez,
                        bhType: eigb.bhType,
                        eigbId: eigb.eigbId,
                        noOfLiegb,
                        price: noOfLiegb * PORTAL_PLUS_PRICE,
                        selected: ko.observable(activeEigbId === eigb.eigbId)
                    };
                });
            });

            vm.hasBuchhaltungenWithPortalPlusDisabledTypeMeg = ko.pureComputed(() => _.some(
                vm.buchhaltungenWithPortalPlusDisabled(), (eigb) => model.eigBuchhaltungType.MEG === eigb.bhType
            ));
            vm.hasBuchhaltungenWithPortalPlusDisabledTypeStewe = ko.pureComputed(() => _.some(
                vm.buchhaltungenWithPortalPlusDisabled(), (eigb) => model.eigBuchhaltungType.STEWE === eigb.bhType
            ));

            vm.selectedDisabledEigbIds = ko.pureComputed(() => vm.buchhaltungenWithPortalPlusDisabled().filter((eigb) => eigb.selected()).map((eigb) => eigb.eigbId));
            vm.priceSelectedDisabledEigb = ko.pureComputed(() => coreViewmodel.formatCurrency(
                vm.buchhaltungenWithPortalPlusDisabled().filter((eigb) => eigb.selected()).reduce((sum, eigb) => sum + eigb.price, 0)
            ));

            vm.executeEnablePortalPlus = function (form) {
                if (ui.validateForm(form) === false) {
                    return;
                }
                vm.isPortalPlusProcessing(true);
                vm.delayedEigbs.removeAll();
                return vm.viewmodel.client.execute(metaProtocol.executeRequest({
                    actionId: model.PORTALPLUS_ENABLE_ACTION_ID,
                    param: model.portalPlusEnableActionParam({
                        eigbIds: vm.selectedDisabledEigbIds()
                    })
                })).then(function (reply) {
                    vm.viewmodel.errorReply(undefined);
                    ui.resetForm(form);
                    vm.delayedEigbs(reply.result.delayedEigbIds.map((eigbId) => vm.viewmodel.resolveBuchhaltung(eigbId)?.bez ?? `${eigbId}`));
                    vm.isEnablePortalPlusActive(false);
                }, function (exc) {
                    vm.viewmodel.errorReply(JSON.stringify(exc, undefined, 4));
                    LOG.warn("executeEnablePortalPlus failed", exc);
                }).then(function () {
                    vm.isPortalPlusProcessing(false);
                });
            };

            vm.withdrawCancellation = function () {
                vm.isPortalPlusProcessing(true);
                return vm.viewmodel.client.execute(metaProtocol.executeRequest({
                    actionId: model.PORTALPLUS_ENABLE_ACTION_ID,
                    param: model.portalPlusCancelActionParam({
                        eigbIds: [vm.activeBuchhaltung().eigbId]
                    })
                })).then(function () {
                    vm.viewmodel.errorReply(undefined);
                }, function (exc) {
                    vm.viewmodel.errorReply(JSON.stringify(exc, undefined, 4));
                    LOG.warn("withdrawCancellation failed", exc);
                }).then(function () {
                    vm.isPortalPlusProcessing(false);
                });
            };

            vm.isPortalPlusHistoryActive = ko.observable(false);
            vm.activatePortalPlusHistory = () => vm.isPortalPlusHistoryActive(true);

            vm.onNavUpdate = function (eigbIdParam) {
                if (eigbIdParam) {
                    const eigbId = Number.parseInt(eigbIdParam);
                    if (_.isFinite(eigbId)) {
                        LOG.debug(`onNavUpdate, eigbId=${eigbId}`);
                        const eigb = vm.buchhaltungen().find((eigb) => eigb.eigbId === eigbId);
                        if (eigb) {
                            vm.activateBuchhaltung(eigb);
                        }
                    }
                }
                vm.viewmodel.resetEigbFilter();
                ko.tasks.schedule(() => vm.hasSearchQueryFocus(true));
            };

            nav.register({
                id: vm.viewmodel.COMPONENTS.BUCHHALTUNG,
                onUpdate: vm.onNavUpdate
            });

            vm.dispose = function () {
                vm.viewmodel.client.unregisterOnEvent(vm.zipsSub);
            };

            ko.when(() => 0 < vm.viewmodel.eigBuchhaltungen().length).then(function () {
                const eigbIdParam = nav.currentParams().get(vm.viewmodel.COMPONENTS.BUCHHALTUNG);
                if (eigbIdParam) {
                    vm.onNavUpdate(eigbIdParam);
                } else {
                    if (0 < vm.buchhaltungenWithNewData().length) {
                        vm.activateBuchhaltung(_.first(vm.buchhaltungenWithNewData()));
                    } else {
                        vm.activateBuchhaltung(_.first(vm.buchhaltungen()));
                    }
                }
                vm.hasSearchQueryFocus(true);
            });

            return vm;
        }
    }
});
