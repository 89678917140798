/*global __version */
import Logger from "js-logger";
import ko from "knockout";
import objects from "meta-client/modules/objects";
import metaProtocol from "meta-core/modules/protocol";
import pdfjsLib from "pdfjs-dist";
import model from "portal-core/modules/model";
import protocol from "portal-core/modules/protocol";
import coreViewmodel from "portal-core/modules/viewmodel";
import _ from "underscore";
import dates from "util-web/modules/dates";
import ext from "util-web/modules/ext";
import nav from "util-web/modules/nav";

pdfjsLib.GlobalWorkerOptions.workerSrc = "./pdf.worker.min.js";

const LOG = Logger.get("portal-web/viewmodel");
const EIGB_GROUP_REGEX = new RegExp(model.EIGBUCHHALTUNG_GROUP_PREFIX + "[0-9]{3,}");
const ERROR_SEARCH_REPLY = metaProtocol.executeReply({
    result: model.searchActionResult({reply: metaProtocol.searchReply({metas: [], total: 0})})
});
const PERS_GROUP_REGEX = new RegExp(model.PERSON_GROUP_PREFIX + "[0-9]{3,}");
const PDF_PAGE_ID_PREFIX = "pdfviewer-p";
const ZIP_FILES_LIMIT = 200;

export default Object.freeze(function ({client, config}) {
    let activeZipTimerContext;
    const eigbLoadFunctions = [];
    const viewmodel = {};

    viewmodel.version = __version;

    viewmodel.client = client;
    viewmodel.config = config;

    // Navigation

    viewmodel.isActive = ko.observable(false);
    viewmodel.activeComponent = ko.observable();

    viewmodel.COMPONENTS = Object.freeze({
        BELEG: "beleg",
        BUCHHALTUNG: "buchhaltung",
        DOKUMENT: "dokument",
        FREIGABE: "freigabe",
        INDEX: "index",
        ONBOARDING: protocol.ONBOARDING_PARAM,
        SECURITY: "security"
    });

    viewmodel.BELEG_TABS = Object.freeze({
        BELEGE: "belege",
        BUCHUNGEN: "buchungen"
    });

    viewmodel.navigateToComponent = function ({
        component,
        params = null,
        state = null
    }) {
        LOG.debug(`navigateToComponent, component=${component}`);
        const newParams = params || nav.newParams();
        newParams.set(viewmodel.COMPONENTS.INDEX, component);
        if (newParams.has(component) === false) {
            newParams.set(component, "");
        }
        return nav.pushState(newParams, state);
    };

    viewmodel.navigateToBeleg = function ({
        state = null,
        tab = viewmodel.BELEG_TABS.BELEGE
    }) {
        const params = nav.newParams();
        params.set(viewmodel.COMPONENTS.BELEG, tab);
        return viewmodel.navigateToComponent({component: viewmodel.COMPONENTS.BELEG, params, state});
    };

    viewmodel.navigateToBuchhaltung = function (eigbId = null) {
        const params = nav.newParams();
        if (eigbId) {
            params.set(viewmodel.COMPONENTS.BUCHHALTUNG, eigbId);
        }
        return viewmodel.navigateToComponent({component: viewmodel.COMPONENTS.BUCHHALTUNG, params});
    };

    viewmodel.navigateToDokument = function (state = null) {
        return viewmodel.navigateToComponent({component: viewmodel.COMPONENTS.DOKUMENT, state});
    };

    viewmodel.navigateToFreigabe = function (state = null) {
        return viewmodel.navigateToComponent({component: viewmodel.COMPONENTS.FREIGABE, state});
    };

    viewmodel.navigateToIndex = function () {
        return viewmodel.navigateToComponent({component: viewmodel.COMPONENTS.INDEX});
    };

    viewmodel.navigateToOnboarding = function () {
        const currentParams = nav.currentParams();
        const params = nav.newParams();
        params.set(viewmodel.COMPONENTS.ONBOARDING, currentParams.get(protocol.ONBOARDING_PARAM) || "");
        if (currentParams.has(protocol.ONBOARDING_TOKEN_PARAM)) {
            params.set(protocol.ONBOARDING_TOKEN_PARAM, currentParams.get(protocol.ONBOARDING_TOKEN_PARAM));
        }
        return nav.pushState(params);
    };

    viewmodel.navigateToSecurity = function () {
        return viewmodel.navigateToComponent({component: viewmodel.COMPONENTS.SECURITY});
    };

    // Misc

    viewmodel.authCallback = ko.observable();
    viewmodel.errorReply = ko.observable();
    viewmodel.isSchaeppiUser = ko.observable(false);
    viewmodel.localeOptions = ko.observableArray();
    viewmodel.loginTimestamp = ko.observable();
    viewmodel.hasNewData = function (eigb) {
        const loginTimestamp = viewmodel.loginTimestamp();
        return Boolean(loginTimestamp && (
            (eigb.belegTimestamp && loginTimestamp < eigb.belegTimestamp) || (eigb.dokumentTimestamp && loginTimestamp < eigb.dokumentTimestamp)
        ));
    };
    viewmodel.userConfig = ko.observable();
    viewmodel.ignoredDokTypes = ko.computed(() => _.get(viewmodel.userConfig(), ["config", "ignoredDokTypes"], []) ?? []);

    viewmodel.onClientNotification = function (clientNotification) {
        LOG.debug("onClientNotification");
        if (activeZipTimerContext) {
            if (protocol.ZIP_READY_NOTIFICATION_ACTION === clientNotification.action) {
                const data = clientNotification.data;
                viewmodel.zipFilesResult(Object.assign(data, {
                    expiration: coreViewmodel.formatDateTime(data.expiration),
                    size: ext.formatBytes(data.size)
                }));
            } else if (protocol.ZIP_ERROR_NOTIFICATION_ACTION === clientNotification.action) {
                viewmodel.zipFilesError(true);
            }
            activeZipTimerContext.stop();
        }
    };

    // Data

    viewmodel.onboardingGroups = ko.observableArray();

    viewmodel.onEigbLoad = function (func) {
        eigbLoadFunctions.push(func);
    };
    viewmodel.onEigbLoad(function () {
        viewmodel.ppAbos.clear();
        viewmodel.eigBuchhaltungenById.clear();
        viewmodel.liegBuchhaltungen.removeAll();
        viewmodel.eigBuchhaltungen.removeAll();
        viewmodel.resetEigbQuery();
        viewmodel.resetLiegbQuery();
        viewmodel.resetPersQuery();
    });

    viewmodel.loadEigb = function (checkSchaeppiUserGroups = false) {
        eigbLoadFunctions.forEach((func) => func());
        const onboardingGroups = viewmodel.onboardingGroups();
        const user = viewmodel.client.user();
        LOG.debug(`loadEigb, onboardingGroups=[${onboardingGroups}], user=${user}`);
        viewmodel.isSchaeppiUser(viewmodel.client.groups().includes(model.SCHAEPI_MA_GROUP));
        const userConfig = viewmodel.client.configs().find((config) => _.isEmpty(config.group) && user === config.user);
        if (viewmodel.isSchaeppiUser() === false && userConfig) {
            viewmodel.userConfig(userConfig);
        }
        const groups = (
            _.isEmpty(onboardingGroups)
                ? viewmodel.client.groups()
                : onboardingGroups
        );
        const eigbIds = groups.filter((group) => EIGB_GROUP_REGEX.test(group)).map((group) => group.substring(model.EIGBUCHHALTUNG_GROUP_PREFIX.length));
        LOG.debug(`loadEigb, eigbIds=[${eigbIds}]`);
        if (_.isEmpty(eigbIds)) {
            if (viewmodel.isSchaeppiUser()) {
                viewmodel.isAdminSettingsActive(true);
            }
            return Promise.resolve(undefined);
        }
        viewmodel.isEigbAdmin(0 < groups.filter((group) => group.indexOf(model.EIGBUCHHALTUNG_ADMIN_GROUP_PREFIX) === 0).length);
        return Promise.all([
            viewmodel.client.search(metaProtocol.searchRequest({
                limit: 0,
                query: model.lucene.newEigbQuery(eigbIds)
            })),
            viewmodel.ppAbos.processSearch(
                viewmodel.client.search(metaProtocol.searchRequest({
                    cursor: viewmodel.ppAbos.searchCursor(),
                    limit: 0,
                    query: viewmodel.ppAbos.newSearchQuery([model.lucene.newEigbIdQueryPart(eigbIds)]),
                    sort: metaProtocol.searchRequestSort({field: "beginDate", reverse: true})
                }))
            )
        ]).then(function (results) {
            const eigbSearchReply = results[0];
            if (Boolean(eigbSearchReply) === false) {
                return;
            }
            const eigBuchhaltungen = eigbSearchReply.metas.map(function (meta) {
                const eigbVm = coreViewmodel.toEigBuchhaltungVm(meta);
                eigbVm.isAdmin(groups.includes(model.EIGBUCHHALTUNG_ADMIN_GROUP_PREFIX + eigbVm.eigbId));
                return eigbVm;
            }).sort(ext.simpleSort.bind(undefined, "bez"));
            viewmodel.eigBuchhaltungen(eigBuchhaltungen);
            viewmodel.eigBuchhaltungenTypes(_.uniq(eigBuchhaltungen.map((eigb) => eigb.bhType)));
            viewmodel.hasPortalPlus(_.some(
                viewmodel.eigBuchhaltungen(), (eigb) => eigb.isAdmin() && eigb.ppOverride === false && coreViewmodel.eigBuchhaltungVm.ppState.ENABLED === eigb.ppState()
            ));
            eigBuchhaltungen.forEach((eigb) => viewmodel.eigBuchhaltungenById.set(eigb.eigbId, eigb));
            viewmodel.liegBuchhaltungen(_.flatten(
                eigBuchhaltungen.map((eigb) => eigb.buchhaltungen.map(
                    (liegb) => coreViewmodel.liegBuchhaltungVm(liegb, eigb.eigbId))
                )
            ).sort(ext.simpleSort.bind(undefined, "bez")));
            const userPersIds = groups.filter((group) => PERS_GROUP_REGEX.test(group)).map(
                (group) => Number.parseInt(group.substring(model.PERSON_GROUP_PREFIX.length))
            );
            const personenSorted = _.flatten(eigBuchhaltungen.map((eigb) => eigb.personen.map(coreViewmodel.persVm))).filter(
                (pers) => userPersIds.includes(pers.persId)
            ).sort(ext.simpleSort.bind(undefined, "persName"));
            viewmodel.userPersonen(_.uniq(personenSorted, false, "persId"));
            if (checkSchaeppiUserGroups && viewmodel.isSchaeppiUser() && userConfig) {
                const currentGroups = eigbIds.map(
                    (eigbId) => model.EIGBUCHHALTUNG_GROUP_PREFIX + eigbId
                ).concat(eigbIds.map(
                    (eigbId) => model.EIGBUCHHALTUNG_ADMIN_GROUP_PREFIX + eigbId
                )).concat(viewmodel.userPersonen().map(
                    (pers) => model.PERSON_GROUP_PREFIX + pers.persId
                ));
                const userGroups = _.get(userConfig, ["config", "groups"], []);
                if (0 < userGroups.length && (userGroups.length !== currentGroups.length || _.difference(currentGroups, userGroups).length !== 0)) {
                    viewmodel.showAdminOwnGroupsHint(true);
                }
            }
            viewmodel.onboardingGroups.removeAll();
        });
    };

    viewmodel.eigBuchhaltungen = ko.observableArray();
    viewmodel.eigBuchhaltungenTypes = ko.observableArray();
    viewmodel.eigBuchhaltungenById = new Map();
    viewmodel.hasPortalPlus = ko.observable(false);
    viewmodel.isEigbAdmin = ko.observable(false);
    viewmodel.liegBuchhaltungen = ko.observableArray();
    viewmodel.showAdminOwnGroupsHint = ko.observable(false);

    viewmodel.ppAbos = objects({
        loadById: objects.loadByMetaId.bind(undefined, viewmodel.client),
        toVm: (meta) => coreViewmodel.toPortalPlusAboVm(meta),
        typeId: model.PORTAL_PLUS_ABO_TYPE_ID,
        unshift: true
    });
    viewmodel.ppAbosSub = viewmodel.client.registerOnEvent(viewmodel.ppAbos);
    viewmodel.ppAboComputed = ko.computed(function () {
        viewmodel.eigBuchhaltungen().forEach(function (eigb) {
            const ppAbos = viewmodel.ppAbos.objects().filter(function (ppAbo) {
                return eigb.eigbId === ppAbo.eigbId();
            }).sort(ext.simpleSort.bind({
                property: "beginDate",
                reverse: true
            }));
            if (_.isEmpty(ppAbos) === false) {
                const ppAbo = ppAbos[0];
                if (dates.now() <= dates.parseDateTime(ppAbo.endeDate())) {
                    if (ppAbo.cancelDate()) {
                        eigb.ppState(coreViewmodel.eigBuchhaltungVm.ppState.CANCELED);
                    } else if (dates.now() < dates.parseDateTime(ppAbo.beginDate())) {
                        eigb.ppState(coreViewmodel.eigBuchhaltungVm.ppState.DELAYED);
                    } else {
                        eigb.ppState(coreViewmodel.eigBuchhaltungVm.ppState.ENABLED);
                    }
                }
            }
        });
    });

    viewmodel.liegBuchhaltungenById = ko.computed(function () {
        const byId = new Map();
        viewmodel.liegBuchhaltungen().forEach((liegb) => byId.set(liegb.liegbId, liegb));
        return byId;
    });

    viewmodel.resolveBuchhaltung = function (id) {
        if (0 < id) {
            if (viewmodel.eigBuchhaltungenById.has(id)) {
                return viewmodel.eigBuchhaltungenById.get(id);
            }
            if (viewmodel.liegBuchhaltungenById().has(id)) {
                return viewmodel.liegBuchhaltungenById().get(id);
            }
            LOG.warn(`resolveBuchhaltung failed, id=${id}`);
        }
    };

    viewmodel.persById = ko.computed(function () {
        const byId = new Map();
        viewmodel.eigBuchhaltungen().forEach(function (eigb) {
            eigb.personen.forEach(function (pers) {
                const key = eigb.eigbId + "-" + pers.persId;
                byId.set(key, pers);
            });
        });
        return byId;
    });

    viewmodel.resolvePerson = function (eigbId, persId) {
        const key = eigbId + "-" + persId;
        return viewmodel.persById().get(key) ?? model.person({persId, persName: persId, persRef: "-"});
    };

    viewmodel.activeAnsprechpartner = ko.observable();

    viewmodel.resolveAnsprechpartner = function ({eigbId, liegbId}) {
        const liegb = _.find(viewmodel.liegBuchhaltungen(), {liegbId});
        if (liegb) {
            return {
                liegb: {
                    ansprechpartner: liegb.ansprechpartner,
                    name: liegb.bez
                }
            };
        }
        const eigb = _.find(viewmodel.eigBuchhaltungen(), {eigbId});
        if (eigb) {
            return {
                eigb: {
                    ansprechpartner: eigb.ansprechpartner,
                    name: eigb.bez
                }
            };
        }
    };

    viewmodel.geschaeftsjahre = ko.pureComputed(function () {
        const eigbs = (
            _.isEmpty(viewmodel.filterEigbSelectedIds())
                ? viewmodel.eigBuchhaltungen()
                : viewmodel.eigBuchhaltungen().filter((eigb) => eigb.selected())
        );
        return _.uniq(_.flatten(eigbs.map(viewmodel.getAccessibleGeschaeftsjahre)).map((geschaeftsjahr) => geschaeftsjahr.geschaeftsjahr)).sort().reverse();
    });

    viewmodel.getAccessibleGeschaeftsjahre = function (eigb) {
        if (viewmodel.isSchaeppiUser()) {
            return eigb.geschaeftsjahre;
        }
        const maxDate = coreViewmodel.eigBuchhaltungVm.ppState.DISABLED === eigb.ppState()
            ? model.getAccessibleGeschaeftsjahrBegin(eigb)
            : model.PP_MIN_DATE;
        return eigb.geschaeftsjahre.filter((geschaeftsjahr) => geschaeftsjahr.geschaeftsjahrUploads && maxDate <= geschaeftsjahr.geschaeftsjahrBegin);
    };

    // Eigb Filter

    viewmodel.filterEigbQuery = ko.observable();

    viewmodel.resetEigbQuery = () => viewmodel.filterEigbQuery(undefined);

    viewmodel.filterEigbVisible = ko.pureComputed(function () {
        const query = viewmodel.filterEigbQuery();
        if (_.isEmpty(query)) {
            return viewmodel.eigBuchhaltungen();
        }
        return viewmodel.eigBuchhaltungen().filter((eigb) => eigb.bez.toLowerCase().includes(query.toLowerCase()));
    });

    viewmodel.filterEigbSelectedIds = ko.pureComputed(() => viewmodel.eigBuchhaltungen().filter((eigb) => eigb.selected()).map((eigb) => eigb.eigbId));

    viewmodel.selectAllEigbVisible = () => viewmodel.filterEigbVisible().forEach((eigb) => eigb.selected(true));

    viewmodel.resetEigbFilter = () => viewmodel.eigBuchhaltungen().forEach((eigb) => eigb.selected(false));

    // Liegb Filter

    viewmodel.filterLiegbSelection = ko.pureComputed(function () {
        const selectedEigbIds = viewmodel.filterEigbSelectedIds();
        if (_.isEmpty(selectedEigbIds)) {
            return viewmodel.liegBuchhaltungen();
        }
        return viewmodel.liegBuchhaltungen().filter(function (liegb) {
            return selectedEigbIds.includes(liegb.eigbId);
        });
    });

    viewmodel.filterLiegbSelection.extend({rateLimit: 500});

    viewmodel.filterLiegbQuery = ko.observable();

    viewmodel.resetLiegbQuery = () => viewmodel.filterLiegbQuery(undefined);

    viewmodel.filterLiegbVisible = ko.pureComputed(function () {
        const query = viewmodel.filterLiegbQuery();
        if (_.isEmpty(query)) {
            return viewmodel.filterLiegbSelection();
        }
        return viewmodel.filterLiegbSelection().filter((liegb) => liegb.bez.toLowerCase().includes(query.toLowerCase()));
    });

    viewmodel.filterLiegbIds = ko.pureComputed(() => viewmodel.filterLiegbSelection().filter((liegb) => liegb.selected()).map((liegb) => liegb.liegbId));

    viewmodel.selectAllLiegbVisible = function () {
        viewmodel.filterLiegbVisible().forEach((liegb) => liegb.selected(true));
    };

    viewmodel.resetLiegbFilter = function () {
        viewmodel.filterLiegbSelection().forEach((liegb) => liegb.selected(false));
    };

    viewmodel.generateFilterLiegbQuery = function () {
        const selectedLiegbIds = viewmodel.filterLiegbIds().join(" ");
        const liegbQuery = (
            _.isEmpty(selectedLiegbIds)
                ? undefined
                : `liegbId:(${selectedLiegbIds})`
        );
        viewmodel.resetEigbQuery();
        viewmodel.resetLiegbQuery();
        return liegbQuery;
    };

    // Pers Filter

    viewmodel.userPersonen = ko.observableArray();

    viewmodel.filterPersSelection = ko.pureComputed(function () {
        const selectedEigbs = viewmodel.eigBuchhaltungen().filter((eigb) => eigb.selected());
        if (_.isEmpty(selectedEigbs)) {
            return viewmodel.userPersonen();
        }
        const userPersonen = _.flatten(selectedEigbs.map((eigb) => eigb.personen)).map((pers) => pers.persId);
        return viewmodel.userPersonen().filter((pers) => userPersonen.includes(pers.persId));
    });
    viewmodel.filterPersSelection.extend({rateLimit: 500});

    viewmodel.filterPersQuery = ko.observable();

    viewmodel.resetPersQuery = () => viewmodel.filterPersQuery(undefined);

    viewmodel.filterPersVisible = ko.pureComputed(function () {
        const query = viewmodel.filterPersQuery();
        if (_.isEmpty(query)) {
            return viewmodel.filterPersSelection();
        }
        return viewmodel.filterPersSelection().filter((pers) => pers.persName.toLowerCase().includes(query.toLowerCase()));
    });

    viewmodel.filterPersSelectedIds = ko.pureComputed(() => viewmodel.filterPersSelection().filter((pers) => pers.selected()).map((pers) => pers.persId));

    viewmodel.selectAllPersVisible = () => viewmodel.filterPersVisible().forEach((pers) => pers.selected(true));

    viewmodel.resetPersFilter = () => viewmodel.filterPersSelection().forEach((pers) => pers.selected(false));

    // Search

    viewmodel.executeSearch = function (
        cursorObservable,
        {
            dateField,
            dateFrom = null,
            dateTo = null,
            persIds = null,
            query = null
        }
    ) {
        LOG.debug(`executeSearch, query=${query}`);
        let eigbIds;
        const selectedLiebg = viewmodel.filterLiegbSelection().filter((liegb) => liegb.selected());
        if (_.isEmpty(selectedLiebg)) {
            eigbIds = viewmodel.filterEigbSelectedIds();
        } else {
            eigbIds = _.uniq(selectedLiebg.map((liegb) => liegb.eigbId));
        }
        return viewmodel.client.execute(metaProtocol.executeRequest({
            actionId: model.SEARCH_ACTION_ID,
            param: model.searchActionParam({
                cursor: cursorObservable(),
                dateField,
                dateFrom,
                dateTo,
                eigbIds,
                persIds,
                query
            })
        })).then(function (reply) {
            viewmodel.errorReply(undefined);
            return reply;
        }, function (exc) {
            viewmodel.errorReply(JSON.stringify(exc, undefined, 4));
            LOG.warn("executeSearch failed", exc);
            return ERROR_SEARCH_REPLY;
        }).then(function (reply) {
            const searchResult = reply.result;
            cursorObservable(searchResult.reply.cursor);
            return searchResult.reply;
        });
    };

    // PDF

    viewmodel.renderPdfPage = function (canvasContainer, noOfPages, pageNo, pdf) {
        return pdf.getPage(pageNo).then(function (page) {
            const originalWidth = page.getViewport({scale: 1}).width;
            const scaleRequired = (canvasContainer.offsetWidth - 20) / originalWidth;
            const viewport = page.getViewport({scale: scaleRequired});
            const pageMarker = document.createElement("p");
            pageMarker.id = PDF_PAGE_ID_PREFIX + pageNo;
            pageMarker.classList.add("mb-0");
            pageMarker.classList.add("pt-2");
            pageMarker.innerText = `${pageNo} / ${noOfPages}`;
            canvasContainer.appendChild(pageMarker);
            const canvas = document.createElement("canvas");
            canvas.classList.add("border");
            canvasContainer.appendChild(canvas);
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            return page.render({canvasContext: canvas.getContext("2d"), viewport}).promise;
        });
    };

    /**
     * renderPdfDocument renders PDF from buffer to elementId canvas.
     *
     * @see [pdf.js]{@link https://github.com/mozilla/pdf.js/blob/master/src/display/api.js}
     */
    viewmodel.renderPdfDocument = function (buffer, elementId, pagination) {
        const canvasContainer = document.getElementById(elementId);
        canvasContainer.innerHTML = "";
        if (Boolean(buffer) === false) {
            return Promise.resolve(undefined);
        }
        const data = new ArrayBuffer(buffer.byteLength);
        new Uint8Array(data).set(new Uint8Array(buffer));
        const loadingTask = pdfjsLib.getDocument({data});
        let noOfPages = 0;
        return loadingTask.promise.then(function (pdf) {
            noOfPages = pdf.numPages;
            const renderPromises = [];
            let pageNo = 1;
            while (pageNo <= noOfPages) {
                renderPromises.push(viewmodel.renderPdfPage(canvasContainer, noOfPages, pageNo, pdf));
                pageNo = pageNo + 1;
            }
            return Promise.all(renderPromises);
        }).then(function () {
            pagination.removeAll();
            if (1 < noOfPages) {
                let pageIndex = 0;
                while (pageIndex < noOfPages) {
                    const pageNo = pageIndex + 1;
                    pagination.push({pageId: PDF_PAGE_ID_PREFIX + pageNo, pageNo});
                    pageIndex = pageIndex + 1;
                }
            }
        });
    };

    // Admin

    viewmodel.isAdminSettingsActive = ko.observable(false);
    viewmodel.adminLoadOwnGroups = ko.observable(false);

    // ZIP

    viewmodel.isZipFilesActive = ko.observable(false);
    viewmodel.isZipFilesActive.subscribe(function (isActive) {
        if (Boolean(isActive) === false) {
            viewmodel.isZippingFiles(false);
        }
    });
    viewmodel.isZipFilesExceeded = ko.observable(false);
    viewmodel.isZippingFiles = ko.observable(false);
    viewmodel.zipFilesResult = ko.observable();
    viewmodel.zipFilesError = ko.observable(false);

    viewmodel.zipFiles = function (timerContext, keys) {
        viewmodel.isZipFilesActive(true);
        if (ZIP_FILES_LIMIT < keys.length) {
            viewmodel.isZipFilesExceeded(true);
            return;
        }
        viewmodel.isZipFilesExceeded(false);
        viewmodel.zipFilesResult(undefined);
        viewmodel.zipFilesError(false);
        viewmodel.isZippingFiles(true);
        activeZipTimerContext = timerContext;
        return viewmodel.client.execute(metaProtocol.executeRequest({
            actionId: model.ZIP_FILES_ACTION_ID,
            param: model.zipFilesActionParam({keys})
        })).then(() => viewmodel.errorReply(undefined), function (exc) {
            viewmodel.errorReply(JSON.stringify(exc, undefined, 4));
            LOG.warn("zipFiles failed", exc);
        });
    };

    return Object.freeze(viewmodel);
});
